import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import classNames from 'classnames';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import ResetPasswordSection from '../components/sections/ResetPasswordSection'
import FloorballSection from '../components/sections/FloorballSection';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { lime, purple } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';

const PrivacyPolicy = () => {
    const appName = "PonyShare"
    const groupName = "PonyStories Group Oy"
    const email = "support@ponystories.com"
    const webPage = "https://ponyshare.app"

    const loginTheme = createTheme({
        palette: {
            mode: 'light',
        },
        components: {
            a: {
                color: 'black'
            }
        }
    });

    const List = ({ children }) => (
        <ul style={{ marginTop: 20 }}>
            {children}
        </ul>
    )
    const OrderedList = ({ children }) => (
        <ol>
            {children}
        </ol>
    )
    const ListItem = ({ children }) => (
        <li>
            {children}
        </li>
    )
    const ListItemText = ({ children }) => (
        <li>
            <Text>
                {children}
            </Text>
        </li>
    )
    const TableItemText = ({ children }) => (
        <td>
            <Text>
                {children}
            </Text>
        </td>
    )

    const ListItemBoldText = ({ children }) => (
        <li>
            <BoldText>
                {children}
            </BoldText>
        </li>
    )

    const Section = (props) => (
        <section {...props} style={{ marginTop: 60 }}>
            {props.children}
        </section>
    )
    const SectionHeader = (props) => (
        <Typography variant="h5" style={{ display: 'block', marginTop: 20 }} gutterBottom {...props}>
            {props.children}
        </Typography>
    )
    const Header = (props) => (
        <Typography variant="h6" style={{ display: 'block', marginTop: 20 }} gutterBottom {...props}>
            {props.children}
        </Typography>
    )
    const Text = ({ children }) => (
        <Typography style={{ display: 'block', marginTop: 20 }} gutterBottom>
            {children}
        </Typography>
    )
    const BoldText = ({ children }) => {
        //const splitted = children.split(/[\.?:](.*)/s)
        const pos = /[\.?:](.*)/s.exec(children).index + 1;
        //console.log("pos ", pos)
        return (
            <Text>
                <Bold>{children.substring(0, pos)}</Bold>
                {children.substring(pos)}
            </Text>
        )

    }
    const LinkTo = (props) => (
        <a style={{ color: 'black' }} href={props.url} target="_blank" >
            {props.children}
        </a>
        /*
        <Link target="_blank" to={props.url}>{props.children}</Link>
        */
    )

    const Bold = ({ children }) => (
        <strong>
            {children}
        </strong>
    )

    const Underline = ({ children }) => (
        <u>
            {children}
        </u>
    )

    const Cursive = ({ children }) => (
        <i>
            {children}
        </i>
    )
    const ScrollTo = ({ children, toId }) => {
        const onClick = () => {
            console.log("Clicked")
            const anchor = document.getElementById(toId)
            if (anchor) {
                anchor.scrollIntoView({ behavior: "smooth" })
            } else {
                console.log("not found")
            }
        }
        return (
            <a style={{ color: 'black' }} onClick={onClick}>
                {children}
            </a >
        )
    }

    const ids = ({
        start: 'start',
        infouse: 'infouse',
        whoshare: 'whoshare',
        privacyrights: 'privacyrights',
        toc: 'toc',
        infocollect: 'infocollect',
        legalbases: 'legalbases',
        twebsites: '3twebsites',
        inforetain: 'inforetain',
        infosafe: 'infosafe',
        infominors: 'infominors',
        dnt: 'dnt',
        caresidents: 'caresidents',
        virginia: 'virginia',
        policyupdates: 'policyupdates',
        contact: 'contact',
        request: 'request',
        withdrawconsent: 'withdrawconsent'
    })



    return (
        <ThemeProvider theme={loginTheme}>
            {
                //<CssBaseline />
            }
            <div className="container" style={{ marginBottom: 40 }}>
                <Typography variant='h3' id={ids.start} style={{ display: 'block', paddingTop: 40 }}>
                    PRIVACY POLICY
                </Typography>

                <Typography variant='h6' style={{ display: 'block' }}>
                    Last updated September 29, 2023
                </Typography>
                <Section>
                    <Text>
                        This privacy policy for PonyShare ("Company", "we", "us", or "our" ),
                        describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                    </Text>
                    <List>
                        <ListItem>
                            Download and use our mobile application ({appName}), or any other application of ours that links to this privacy policy
                        </ListItem>
                        <ListItem>
                            Engage with us in other related ways, including any sales, marketing, or events
                        </ListItem>
                    </List>
                    <Text>
                        <Bold>
                            Questions or concerns?
                        </Bold> Reading this privacy policy will help you understand your privacy rights and choices.
                        If you do not agree with our policies and practices, please do not use our Services.
                        If you still have any questions or concerns, please contact us at {email}.
                    </Text>
                </Section>
                <Section>
                    <SectionHeader>
                        SUMMARY OF KEY POINTS
                    </SectionHeader>
                    <Bold>
                        This summary provides key points from our privacy policy,
                        but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
                        You can also click <ScrollTo toId={ids.toc}>here</ScrollTo> to go directly to our table of contents.
                    </Bold>
                    <Text>
                        <Bold>What personal information do we process?</Bold> When you visit, use, or navigate our Services,
                        we may process personal information depending on how you interact with PonyShare and the Services, the choices you make,
                        and the products and features you use. Click <ScrollTo toId={ids.infocollect}>here</ScrollTo> to learn more.
                    </Text>
                    <Text>
                        <Bold>Do we process any sensitive personal information?</Bold> We do not process sensitive personal information.
                    </Text>
                    <Text>
                        <Bold>Do we receive any information from third parties?</Bold> We do not receive any information from third parties.

                    </Text>
                    <Text>
                        <Bold>How do we process your information?</Bold> We process your information to provide, improve, and administer our Services, communicate with you,
                        for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                        We process your information only when we have a valid legal reason to do so. Click <ScrollTo toId={ids.infouse}>here</ScrollTo> to learn more.
                    </Text>
                    <Text>
                        <Bold>In what situations and with which parties do we share personal information?</Bold> We may share information in specific situations and with specific third parties.
                        Click <ScrollTo toId={ids.whoshare}>here</ScrollTo> to learn more.
                    </Text>
                    <Text>
                        <Bold>How do we keep your information safe?</Bold> We have organizational and technical processes and procedures in place to protect your personal information.
                        However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                        cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
                        Click <ScrollTo toId={ids.infouse}>here</ScrollTo> to learn more.
                    </Text>
                    <Text>
                        <Bold>What are your rights?</Bold> Depending on where you are located geographically,
                        the applicable privacy law may mean you have certain rights regarding your personal information.
                        Click <ScrollTo toId={ids.privacyrights}>here</ScrollTo> to learn more.
                    </Text>
                    <Text>
                        <Bold>How do you exercise your rights?</Bold> The easiest way to exercise your rights is by filling out our data subject request form available
                        here: {email}, or by contacting us.
                        We will consider and act upon any request in accordance with applicable data protection laws.
                    </Text>
                    <Text>
                        Want to learn more about what PonyShare does with any information we collect?
                        Click <ScrollTo toId={ids.toc}>here</ScrollTo> to review the policy in full.
                    </Text>

                </Section>
                <Section>
                    <SectionHeader id={ids.toc}>
                        TABLE OF CONTENTS
                    </SectionHeader>
                    <OrderedList>
                        <ListItem><ScrollTo toId={ids.infocollect}>WHAT INFORMATION DO WE COLLECT? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.infouse}>HOW DO WE PROCESS YOUR INFORMATION? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.legalbases}>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.whoshare}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.twebsites}>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.inforetain}>HOW LONG DO WE KEEP YOUR INFORMATION? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.infosafe}>HOW DO WE KEEP YOUR INFORMATION SAFE? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.infominors}>DO WE COLLECT INFORMATION FROM MINORS? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.privacyrights}>WHAT ARE YOUR PRIVACY RIGHTS? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.dnt}>CONTROLS FOR DO-NOT-TRACK FEATURES </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.caresidents}>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.virginia}>DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.policyupdates}>DO WE MAKE UPDATES TO THIS POLICY? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.contact}>HOW CAN YOU CONTACT US ABOUT THIS POLICY? </ScrollTo> </ListItem>
                        <ListItem><ScrollTo toId={ids.request}>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? </ScrollTo> </ListItem>
                    </OrderedList>
                </Section>

                <Section>
                    <SectionHeader id={ids.infocollect}>
                        1. WHAT INFORMATION DO WE COLLECT?
                    </SectionHeader>
                    <Header>Personal information you disclose to us</Header>
                    <Text>
                        <Cursive>
                            <Bold>In Short:</Bold> We collect personal information that you provide to us.
                        </Cursive>
                    </Text>
                    <Text>
                        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or
                        our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                    </Text>
                    <Text>
                        <Bold>Personal Information Provided by You.</Bold> The personal information that we collect depends on the context of your interactions with us and the Services,
                        the choices you make, and the products and features you use.
                        The personal information we collect may include the following:
                    </Text>
                    <List>
                        <ListItem>names</ListItem>
                        <ListItem>phone numbers</ListItem>
                        <ListItem>email addresses</ListItem>
                        <ListItem>contact or authentication data</ListItem>
                        <ListItem>contact preferences</ListItem>
                    </List>

                    <Text>
                        <Bold>Sensitive Information.</Bold> We do not process sensitive information
                    </Text>
                    <Text>
                        <Bold>Payment Data.</Bold> We may collect data necessary to process your payment if you make purchases,
                        such as your payment instrument number, and the security code associated with your payment instrument.
                        All payment data is stored by Play Store and App Store. You may find their privacy policy links
                        here: <LinkTo url={'https://policies.google.com/privacy?hl=en'}>Google Privacy Policy</LinkTo> and <LinkTo url={'https://www.apple.com/legal/privacy/'}>Apple’s Privacy Policy</LinkTo>.
                    </Text>
                    <Text>
                        <Bold>Application Data.</Bold> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
                    </Text>
                    <List>
                        <ListItem>
                            <Cursive>Mobile Device Access.</Cursive> We may request access or permission to certain features from your mobile device,
                            including your mobile device's camera, contacts, and other features.
                            If you wish to change our access or permissions, you may do so in your device's settings.
                        </ListItem>
                        <ListItem>
                            <Cursive>Mobile Device Data.</Cursive> We automatically collect device information (such as your mobile device ID, model, and manufacturer),
                            operating system, version information and system configuration information, device and application identification numbers, browser type and version,
                            hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s),
                            we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform,
                            the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
                        </ListItem>
                        <ListItem>
                            <Cursive>Push Notifications.</Cursive> We may request to send you push notifications regarding your account or certain features of the application(s).
                            If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                        </ListItem>
                        <ListItem>
                            <Cursive>Image from Gallery or Camera.</Cursive> We may request access or permission to gallery or camera.
                            The image can be shared with group members or processed with OCR to convert receipt to text.
                        </ListItem>
                        <ListItem>
                            <Cursive>Contacts information.</Cursive> We may request access or permission to Contacts to more easily connect you with your friends.
                        </ListItem>
                    </List>

                    <Text>
                        This information is primarily needed to maintain the security and operation of our application(s),
                        for troubleshooting, and for our internal analytics and reporting purposes.
                    </Text>
                    <Text>
                        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information
                    </Text>

                    <Header>Information automatically collected</Header>

                    <Text>
                        <Cursive>
                            <Bold>In Short:</Bold> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                        </Cursive>
                    </Text>
                    <Text>
                        We automatically collect certain information when you visit, use, or navigate the Services.
                        This information does not reveal your specific identity (like your name or contact information) but may include device and usage information,
                        such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location,
                        information about how and when you use our Services, and other technical information.
                        This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                    </Text>
                    <Text>
                        The information we collect includes:
                    </Text>
                    <List>
                        <ListItem>
                            <Cursive>Log and Usage Data.</Cursive> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect
                            when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address,
                            device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage,
                            pages and files viewed, searches, and other actions you take such as which features you use),
                            device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
                        </ListItem>
                    </List>
                </Section>
                <Section>
                    <SectionHeader id={ids.infouse}>2. HOW DO WE PROCESS YOUR INFORMATION?</SectionHeader>
                    <Text>
                        <Cursive>
                            <Bold>
                                In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
                                We may also process your information for other purposes with your consent.
                            </Bold>
                        </Cursive>
                    </Text>
                    <Text>
                        <Bold>
                            We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                        </Bold>
                    </Text>
                    <List>
                        <ListItem>
                            <BoldText>
                                To facilitate account creation and authentication and otherwise manage user accounts.
                                We may process your information so you can create and log in to your account, as well as keep your account in working order.
                            </BoldText>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                To deliver and facilitate delivery of services to the user.
                                We may process your information to provide you with the requested service.
                            </BoldText>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                To enable user-to-user communications.
                                We may process your information if you choose to use any of our offerings that allow for communication with another user.
                            </BoldText>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                To deliver targeted advertising to you.
                                We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.
                            </BoldText>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                To save or protect an individual's vital interest.
                                We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                            </BoldText>
                        </ListItem>
                    </List>
                </Section>

                <Section>
                    <SectionHeader id={ids.legalbases}>
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short:
                            We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so
                            under applicable law, like with your consent, to comply with laws,
                            to provide you with services to enter into or fulfill our contractual obligations,
                            to protect your rights, or to fulfill our legitimate business interests.
                        </BoldText>
                    </Cursive>
                    <Text>
                        <Bold>
                            If you are located in the EU or UK, this section applies to you.
                        </Bold>
                    </Text>
                    <Text>
                        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information.
                        As such, we may rely on the following legal bases to process your personal information:
                    </Text>
                    <List>
                        <ListItem>
                            <Text>
                                <Bold>Consent.</Bold> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose.
                                You can withdraw your consent at any time. Click <ScrollTo to={ids.withdrawconsent}>here</ScrollTo> to learn more.
                            </Text>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                Performance of a Contract.
                                We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you,
                                including providing our Services or at your request prior to entering into a contract with you.
                            </BoldText>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and
                                those interests do not outweigh your interests and fundamental rights and freedoms.
                                For example, we may process your personal information for some of the purposes described in order to:
                            </BoldText>
                            <List>
                                <ListItem>
                                    <Text>
                                        Develop and display personalized and relevant advertising content for our users
                                    </Text>
                                </ListItem>
                            </List>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations,
                                such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights,
                                or disclose your information as evidence in litigation in which we are involved.
                            </BoldText>
                        </ListItem>
                        <ListItem>
                            <BoldText>
                                Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party,
                                such as situations involving potential threats to the safety of any person.
                            </BoldText>
                        </ListItem>
                    </List>

                    <Text>
                        <Bold>
                            If you are located in Canada, this section applies to you.
                        </Bold>
                    </Text>
                    <Text>
                        We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose,
                        or in situations where your permission can be inferred (i.e., implied consent).
                        You can withdraw your consent at any time. Click <ScrollTo to={ids.withdrawconsent}>here</ScrollTo> to learn more.
                    </Text>
                    <Text>
                        In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                    </Text>
                    <List>
                        <ListItemText>
                            If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
                        </ListItemText>
                        <ListItemText>
                            For investigations and fraud detection and prevention
                        </ListItemText>
                        <ListItemText>
                            For business transactions provided certain conditions are met
                        </ListItemText>
                        <ListItemText>
                            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
                        </ListItemText>
                        <ListItemText>
                            For identifying injured, ill, or deceased persons and communicating with next of kin
                        </ListItemText>
                        <ListItemText>
                            If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
                        </ListItemText>
                        <ListItemText>
                            If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and
                            the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
                        </ListItemText>
                        <ListItemText>
                            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
                        </ListItemText>
                        <ListItemText>
                            If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
                        </ListItemText>
                        <ListItemText>
                            If the collection is solely for journalistic, artistic, or literary purposes
                        </ListItemText>
                        <ListItemText>
                            If the information is publicly available and is specified by the regulations
                        </ListItemText>
                    </List>
                </Section>

                <Section>
                    <SectionHeader id={ids.whoshare}>
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: We may share information in specific situations described in this section and/or with the following third parties.
                        </BoldText>
                    </Cursive>

                    <Text>
                        We may need to share your personal information in the following situations:
                    </Text>

                    <List>
                        <ListItemBoldText>
                            Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger,
                            sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </ListItemBoldText>
                        <ListItemBoldText>
                            Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or
                            otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly
                            made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity,
                            communicate with you within our Services, and view your profile.
                        </ListItemBoldText>
                    </List>
                </Section>
                <Section>
                    <SectionHeader id={ids.twebsites}>
                        5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: We are not responsible for the safety of any information that you share with third parties
                            that we may link to or who advertise on our Services,
                            but are not affiliated with, our Services.
                        </BoldText>
                    </Cursive>
                    <Text>
                        The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements
                        from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly,
                        we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such
                        third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or
                        application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties.
                        Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security
                        practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services.
                        You should review the policies of such third parties and contact them directly to respond to your questions.
                    </Text>
                </Section>

                <Section>
                    <SectionHeader id={ids.inforetain}>
                        6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
                        </BoldText>
                    </Cursive>
                    <Text>
                        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy,
                        unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
                        No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.
                    </Text>
                    <Text>
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or,
                        if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store
                        your personal information and isolate it from any further processing until deletion is possible.
                    </Text>
                </Section>

                <Section>
                    <SectionHeader id={ids.infosafe}>
                        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: We aim to protect your personal information through a system of organizational and technical security measures.
                        </BoldText>
                    </Cursive>
                    <Text>
                        We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of
                        any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over
                        the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals,
                        or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
                        Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk.
                        You should only access the Services within a secure environment.
                    </Text>
                </Section>


                <Section>
                    <SectionHeader id={ids.infominors}>
                        8. DO WE COLLECT INFORMATION FROM MINORS?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: We do not knowingly collect data from or market to children under 18 years of age.
                        </BoldText>
                    </Cursive>
                    <Text>
                        We do not knowingly solicit data from or market to children under 18 years of age.
                        By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                        minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of
                        age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.
                        If you become aware of any data we may have collected from children under age 18, please contact us at {email}.

                    </Text>
                </Section>


                <Section>
                    <SectionHeader id={ids.privacyrights}>
                        9. WHAT ARE YOUR PRIVACY RIGHTS?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and
                            control over your personal information. You may review, change, or terminate your account at any time.
                        </BoldText>
                    </Cursive>
                    <Text>
                        In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws.
                        These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of
                        your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.
                        You can make such a request by contacting us by using the contact details provided in the section <ScrollTo to={ids.contact}>"HOW CAN YOU CONTACT US ABOUT THIS POLICY?"</ScrollTo> below.
                    </Text>
                    <Text>
                        We will consider and act upon any request in accordance with applicable data protection laws.
                    </Text>
                    <Text>
                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
                        you also have the right to complain to your local data protection supervisory authority.
                        You can find their contact details <LinkTo url='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>here</LinkTo>.
                    </Text>
                    <Text>
                        If you are located in Switzerland, the contact details for the data protection authorities are available
                        <LinkTo url='https://www.edoeb.admin.ch/edoeb/en/home.html'>here</LinkTo>.
                    </Text>

                    <Text>
                        <Bold><Cursive>Withdrawing your consent: </Cursive></Bold>
                        If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law,
                        you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details
                        provided in the section <ScrollTo to={ids.contact}>"HOW CAN YOU CONTACT US ABOUT THIS POLICY?"</ScrollTo> below or updating your preferences.
                    </Text>
                    <Text>
                        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows,
                        will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                    </Text>
                    <Header>
                        Account Information
                    </Header>
                    <Text>
                        If you would at any time like to review or change the information in your account or terminate your account, you can:
                    </Text>
                    <List>
                        <ListItemText>
                            Log in to your account settings and update your user account.
                        </ListItemText>
                        <ListItemText>
                            Send email to {email} and request update.
                        </ListItemText>
                    </List>
                    <Text>
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases.
                        However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations,
                        enforce our legal terms and/or comply with applicable legal requirements.
                    </Text>
                    <Text>
                        If you have questions or comments about your privacy rights, you may email us at {email}.
                    </Text>
                </Section>


                <Section>
                    <SectionHeader id={ids.dnt}>
                        10. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </SectionHeader>
                    <Text>
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or
                        setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.
                        At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized.
                        As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
                        If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.
                    </Text>
                </Section>


                <Section>
                    <SectionHeader id={ids.caresidents}>
                        11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                        </BoldText>
                    </Cursive>
                    <Text>
                        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us,
                        once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
                        the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year.
                        If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    </Text>
                    <Text>
                        If you are under 18 years of age, reside in California, and have a registered account with Services,
                        you have the right to request removal of unwanted data that you publicly post on the Services.
                        To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and
                        a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or
                        comprehensively removed from all our systems (e.g., backups, etc.).
                    </Text>
                    <Header>
                        CCPA Privacy Policy
                    </Header>
                    <Text>
                        The California Code of Regulations defines a "resident" as:
                    </Text>
                    <OrderedList>
                        <ListItemText>
                            every individual who is in the State of California for other than a temporary or transitory purpose and
                        </ListItemText>
                        <ListItemText>
                            every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
                        </ListItemText>
                    </OrderedList>

                    <Text>
                        All other individuals are defined as "non-residents."
                    </Text>
                    <Text>
                        If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    </Text>
                    <Text>
                        <Bold>
                            What categories of personal information do we collect?
                        </Bold>
                    </Text>
                    <Text>
                        We have collected the following categories of personal information in the past twelve (12) months:
                    </Text>
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    Category
                                </th>
                                <th>
                                    Examples
                                </th>
                                <th>
                                    Collected
                                </th>
                            </tr>
                            <tr>
                                <TableItemText>
                                    A. Identifiers
                                </TableItemText>
                                <TableItemText>
                                    Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier,
                                    Internet Protocol address, email address, and account name
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    B. Personal information categories listed in the California Customer Records statute
                                </TableItemText>
                                <TableItemText>
                                    Name, contact information, education, employment, employment history, and financial information
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    C. Protected classification characteristics under California or federal law
                                </TableItemText>
                                <TableItemText>
                                    Gender and date of birth
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    D. Commercial information
                                </TableItemText>
                                <TableItemText>
                                    Transaction information, purchase history, financial details, and payment information
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    E. Biometric information
                                </TableItemText>
                                <TableItemText>
                                    Fingerprints and voiceprints
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    F. Internet or other similar network activity
                                </TableItemText>
                                <TableItemText>
                                    Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    G. Geolocation data
                                </TableItemText>
                                <TableItemText>
                                    Device location
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    H. Audio, electronic, visual, thermal, olfactory, or similar information
                                </TableItemText>
                                <TableItemText>
                                    Images and audio, video or call recordings created in connection with our business activities
                                </TableItemText>
                                <TableItemText>
                                    YES
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    I. Professional or employment-related information
                                </TableItemText>
                                <TableItemText>
                                    Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    J. Education Information
                                </TableItemText>
                                <TableItemText>
                                    Student records and directory information
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    K. Inferences drawn from other personal information
                                </TableItemText>
                                <TableItemText>
                                    Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                            <tr>
                                <TableItemText>
                                    L. Sensitive Personal Information
                                </TableItemText>
                                <TableItemText>

                                </TableItemText>
                                <TableItemText>
                                    NO
                                </TableItemText>
                            </tr>
                        </tbody>
                    </table>
                    <Text>
                        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                    </Text>
                    <List>
                        <ListItemText>
                            Receiving help through our customer support channels;
                        </ListItemText>
                        <ListItemText>
                            Participation in customer surveys or contests; and
                        </ListItemText>
                        <ListItemText>
                            Facilitation in the delivery of our Services and to respond to your inquiries.
                        </ListItemText>
                    </List>
                    <Text>
                        <Bold>
                            How do we use and share your personal information?
                        </Bold>
                    </Text>
                    <Text>
                        More information about our data collection and sharing practices can be found in this privacy policy.

                        You may contact us by email at {email}, or by referring to the contact details at the bottom of this document.

                        If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

                    </Text>

                    <Text>
                        <Bold>
                            Will your information be shared with anyone else?
                        </Bold>
                    </Text>
                    <Text>
                        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.

                        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.

                        PonyShare has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. PonyShare will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                    </Text>

                    <Text>
                        <Bold>
                            Your rights with respect to your personal data
                        </Bold>
                    </Text>
                    <Text>
                        <Underline>
                            Right to request deletion of the data — Request to delete
                        </Underline>
                    </Text>
                    <Text>
                        You can ask for the deletion of your personal information.
                        If you ask us to delete your personal information, we will respect your request and delete your personal information,
                        subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech,
                        our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
                    </Text>
                    <Text>
                        <Underline>
                            Right to be informed — Request to know
                        </Underline>
                    </Text>
                    <Text>
                        Depending on the circumstances, you have a right to know:
                    </Text>
                    <List>
                        <ListItemText>
                            whether we collect and use your personal information;
                        </ListItemText>
                        <ListItemText>
                            the categories of personal information that we collect;
                        </ListItemText>
                        <ListItemText>
                            the purposes for which the collected personal information is used;
                        </ListItemText>
                        <ListItemText>
                            whether we sell or share personal information to third parties;
                        </ListItemText>
                        <ListItemText>
                            the categories of personal information that we sold, shared, or disclosed for a business purpose;
                        </ListItemText>
                        <ListItemText>
                            the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;
                        </ListItemText>
                        <ListItemText>
                            the business or commercial purpose for collecting, selling, or sharing personal information; and
                        </ListItemText>
                        <ListItemText>
                            the specific pieces of personal information we collected about you.
                        </ListItemText>
                    </List>

                    <Text>
                        In accordance with applicable law,
                        we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
                    </Text>
                    <Text>
                        <Underline>
                            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                        </Underline>
                    </Text>
                    <Text>
                        We will not discriminate against you if you exercise your privacy rights.
                    </Text>

                    <Text>
                        <Underline>
                            Right to Limit Use and Disclosure of Sensitive Personal Information
                        </Underline>
                    </Text>
                    <Text>
                        We do not process consumer's sensitive personal information.
                    </Text>

                    <Text>
                        <Underline>
                            Verification process

                        </Underline>
                    </Text>
                    <Text>
                        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system.
                        These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us.
                        For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with
                        the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us.
                        We may also use other verification methods as the circumstances dictate.
                    </Text>
                    <Text>
                        We will only use personal information provided in your request to verify your identity or authority to make the request.
                        To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However,
                        if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity
                        and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                    </Text>

                    <Text>
                        <Underline>
                            Other privacy rights
                        </Underline>
                    </Text>
                    <List>
                        <ListItemText>
                            You may object to the processing of your personal information.
                        </ListItemText>
                        <ListItemText>
                            You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
                        </ListItemText>
                        <ListItemText>
                            You can designate an authorized agent to make a request under the CCPA on your behalf.
                            We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
                        </ListItemText>
                        <ListItemText>
                            You may request to opt out from future selling or sharing of your personal information to third parties.
                            Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.
                        </ListItemText>
                    </List>
                    <Text>
                        To exercise these rights, you can contact us by email at {email},
                        or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
                    </Text>
                </Section>


                <Section>
                    <SectionHeader id={ids.virginia}>
                        12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.
                        </BoldText>
                    </Cursive>
                    <Header>
                        Virginia CDPA Privacy Policy
                    </Header>
                    <Text>
                        Under the Virginia Consumer Data Protection Act (CDPA):
                    </Text>
                    <Text>
                        "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context.
                        It does not include a natural person acting in a commercial or employment context.
                    </Text>
                    <Text>
                        "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person.
                        "Personal data" does not include de-identified data or publicly available information.
                    </Text>
                    <Text>
                        "Sale of personal data" means the exchange of personal data for monetary consideration.
                    </Text>
                    <Text>
                        If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.
                    </Text>
                    <Text>
                        The information we collect, use, and disclose about you will vary depending on how you interact with PonyShare and our Services.
                        To find out more, please visit the following links:
                    </Text>
                    <List>
                        <ListItemText>
                            <ScrollTo to={ids.infocollect}>Personal data we collect</ScrollTo>
                        </ListItemText>
                        <ListItemText>
                            <ScrollTo to={ids.infouse}>How we use your personal data</ScrollTo>
                        </ListItemText>
                        <ListItemText>
                            <ScrollTo to={ids.whoshare}>When and with whom we share your personal data</ScrollTo>
                        </ListItemText>
                    </List>

                    <Text>
                        <Underline>
                            Your rights with respect to your personal data
                        </Underline>
                    </Text>

                    <List>
                        <ListItemText>
                            Right to be informed whether or not we are processing your personal data
                        </ListItemText>
                        <ListItemText>
                            Right to access your personal data
                        </ListItemText>
                        <ListItemText>
                            Right to correct inaccuracies in your personal data
                        </ListItemText>
                        <ListItemText>
                            Right to request deletion of your personal data
                        </ListItemText>
                        <ListItemText>
                            Right to obtain a copy of the personal data you previously shared with us
                        </ListItemText>
                        <ListItemText>
                            Right to opt out of the processing of your personal data if it is used for targeted advertising,
                            the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
                        </ListItemText>
                    </List>
                    <Text>
                        {appName} has not sold any personal data to third parties for business or commercial purposes.
                        {appName} will not sell personal data in the future belonging to website visitors, users, and other consumers.
                    </Text>

                    <Text>
                        <Underline>
                            Exercise your rights provided under the Virginia CDPA
                        </Underline>
                    </Text>
                    <Text>
                        More information about our data collection and sharing practices can be found in this privacy policy.
                    </Text>
                    <Text>
                        You may contact us by email at {email} or by referring to the contact details at the bottom of this document.
                    </Text>
                    <Text>
                        If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                    </Text>

                    <Text>
                        <Underline>
                            Verification process
                        </Underline>
                    </Text>
                    <Text>
                        We may request that you provide additional information reasonably necessary to verify you and your consumer's request.
                        If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.

                    </Text>
                    <Text>
                        Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt.
                        The response period may be extended once by forty-five (45) additional days when reasonably necessary.
                        We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.

                    </Text>


                    <Text>
                        <Underline>
                            Right to appeal
                        </Underline>
                    </Text>
                    <Text>
                        If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision,
                        please email us at {email}. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal,
                        including a written explanation of the reasons for the decisions.
                        If your appeal if denied, you may contact the Attorney General to <LinkTo url='https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint'>submit a complaint</LinkTo>.
                    </Text>
                </Section>


                <Section>
                    <SectionHeader id={ids.policyupdates}>
                        13. DO WE MAKE UPDATES TO THIS POLICY?
                    </SectionHeader>
                    <Cursive>
                        <BoldText>
                            In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
                        </BoldText>
                    </Cursive>
                    <Text>
                        We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible.
                        If we make material changes to this privacy policy, we may notify you either by prominently posting a policy of such changes or by directly sending you a notification.
                        We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
                    </Text>
                </Section>

                <Section>
                    <SectionHeader id={ids.contact}>
                        14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </SectionHeader>
                    <Text>
                        If you have questions or comments about this policy, you may email us.
                    </Text>
                    <Text>
                        {email} <br />
                        {appName}<br />
                        {webPage}<br />
                        {groupName} - 3345561-8<br />
                        Finland
                    </Text>
                </Section>
                <Section>
                    <SectionHeader id={ids.request}>
                        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </SectionHeader>
                    <Text>
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it.
                        To request to review, update, or delete your personal information, please visit: {email}.

                    </Text>
                </Section>

            </div >
        </ThemeProvider>
    );
}

export default PrivacyPolicy;