import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import ResetPasswordSection from '../components/sections/ResetPasswordSection'
import FloorballSection from '../components/sections/FloorballSection';

const Floorball = () => {

    return (
        <>

            <FloorballSection className="illustration-section-01" />

            {
                //<ProfileSection className="illustration-section-01" />
                //<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
            }
            {/*<Testimonial topDivider />*/}
            {/*<Cta split />*/}
        </>
    );
}

export default Floorball;