import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const LayoutPrivacy = ({ children }) => {

    const [isWhite, setWhite] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('white')) setWhite(true);
    }, []);

    return (
        <>
            <main className={isWhite ? "site-content invert-color has-bg-color" : "site-content"}>
                {children}
            </main>
            {
                //<Banner />
            }
        </>
    )
};

export default LayoutPrivacy;  