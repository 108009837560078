import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faLastfmSquare,
    faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import '../../App.css';

//import profiles from '../../assets/profiles.json'

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const ProfileSection = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const { name } = useParams()
    const navigate = useNavigate();
    console.log("name: ", name)
    const [videoModalActive, setVideomodalactive] = useState(false);

    const [profile, setProfile] = useState(null)
    useEffect(() => {
        fetch(process.env.PUBLIC_URL + '/data/profiles.json').then((res) => res.json()).then((data) => {
            const selected = data[name];
            setProfile();
            if (selected === undefined) {
                navigate('/');
            } else {
                setProfile(selected);
                console.log("profile", profile);
            }

        })

    }, [])

    const openModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }
    /*
        const profiles = {
            pete: {
                name: "Petri Kaitajärvi",
                number: "+358 40 5266877",
                email: "petri.kaitajarvi@ponystories.com",
                social: {
                    instagram: "https://www.instagram.com/ponyshare/",
                    youtube: "https://www.youtube.com/@ponyshare",
                    linkedin: "https://fi.linkedin.com/in/petri-kaitaj%C3%A4rvi-146975a8"
                }
            },
            olli: {
                name: "Olli Huhtakangas",
                number: "--",
                email: "--",
                social: {
                    instagram: "https://www.instagram.com/ponyshare/",
                    youtube: "https://www.youtube.com/@ponyshare",
                }
            }
        }
        */


    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className="hero-inner ">
                    <div className="hero-content">
                        <Image
                            style={{ marginBottom: 20 }}
                            className="has-shadow"
                            src={require('./../../assets/images/logo_transparent_background.png')}
                            alt="Hero"
                            width={896}
                            height={504} />
                        <div className="container-m">
                            <Image
                                style={{ marginBottom: 20 }}
                                className="has-shadow"
                                src={require('./../../assets/images/pony_logo_round.png')}
                                alt="Hero"
                                width={100}
                            //height={100} 
                            />
                            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                                {profile?.name}
                            </h1>
                        </div>
                        <div className="container-xs">
                            <h3 className="m-0 mb-32 reveal-from-bottom" style={{ marginBottom: 10 }} data-reveal-delay="400">
                                {profile?.title}
                            </h3>
                            <p className="m-0 mb-32 reveal-from-bottom" style={{ marginBottom: 10 }} data-reveal-delay="400">
                                Phone:
                            </p>
                            <p className="reveal-from-bottom" data-reveal-delay="400">
                                {profile?.number}
                            </p>
                            <p className="m-0 mb-32 reveal-from-bottom" style={{ marginBottom: 10 }} data-reveal-delay="400">
                                Email:
                            </p>
                            <p className="reveal-from-bottom is-revealed" data-reveal-delay="400">
                                {profile?.email}
                            </p>
                            {profile?.vcard &&
                                <a href={process.env.PUBLIC_URL + '/data/' + profile.vcard}
                                    download
                                >
                                    <Button color="dark">Download Contact</Button>
                                </a>
                            }
                        </div>
                        <div className="social-container">
                            {profile?.social.linkedin &&
                                <a href={profile.social.linkedin}
                                    className="youtube social">
                                    <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                                </a>
                            }
                            {profile?.social.youtube &&
                                <a href={profile.social.youtube}
                                    className="youtube social">
                                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                                </a>
                            }

                            {profile?.social.facebook &&
                                <a href={profile.social.facebook}
                                    className="facebook social">
                                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                                </a>
                            }

                            {profile?.social.instagram &&
                                <a href={profile.social.instagram}
                                    className="instagram social">
                                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                                </a>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

ProfileSection.propTypes = propTypes;
ProfileSection.defaultProps = defaultProps;

export default ProfileSection;