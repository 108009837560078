import React, { useEffect } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import FaqSection from '../components/sections/FaqSection';

const Home = () => {

  useEffect(() => {
    const href = window.location.href
    if (href.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`
      const anchor = document.getElementById(id)
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  return (
    <>

      <Hero className="illustration-section-01" />
      <FeaturesTiles className="illustration-section-01" />
      <FaqSection id='faq' className="illustration-section-01" />
      {
        //<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      }
      {/*<Testimonial topDivider />*/}
      {/*<Cta split />*/}
    </>
  );
}

export default Home;