import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image';
import Row from '../Row';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';


const FlooerballHeader = ({ match }) => {


    return (
        <div style={match.category_group_name != "Miehet" ? { color: '#F8B9D4' } : { color: '#89CFF0' }}>
            {match.category_name}<br />
            {(match.status != 'Played' && match.status != 'Fixture') ? match.live_minutes + "'" : match.date + ' ' + match.time.substring(0, 5)}

        </div>
    );

};

export default FlooerballHeader;