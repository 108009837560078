import React, { useState, memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const FloorballLineups = ({ match }) => {

    const [showLineups, setShowLineups] = useState(false)
    const lines = [1, 2, 3, 4];

    /*
    const setLineups = () => {
        match?.lineups?.map((player) => {
            if (player?.team_id === match?.team_A_id) {

            } else if (player?.team_id === match?.team_B_id) {

            }
        })
    }
    */

    const searchPlayer = (line, pos, home) => {
        return match?.lineups?.find(player => (player.position === pos + '/' + line && (player?.team_id === match?.team_A_id) == home))
    }

    const ShowLine = ({ line, pos }) => (
        <div style={{ display: 'flex', margin: 0, fontSize: 14 }}>
            <div style={{ width: '48%', paddingRight: 10, textAlign: 'right' }}>
                {searchPlayer(line, pos, true) &&
                    searchPlayer(line, pos, true)?.last_name + ' ' + searchPlayer(line, pos, true)?.first_name[0] + '. #' + searchPlayer(line, pos, true)?.shirt_number
                }
            </div>
            <div style={{ textAlign: 'center' }}>
                {pos}
            </div>
            <div style={{ paddingLeft: 10, width: '48%', textAlign: 'left' }}>
                {searchPlayer(line, pos, false) &&
                    searchPlayer(line, pos, false)?.last_name + ' ' + searchPlayer(line, pos, false)?.first_name[0] + '. #' + searchPlayer(line, pos, false)?.shirt_number
                }
            </div>
        </div>
    );

    return (
        <div>
            <Button variant="outlined" onClick={() => setShowLineups(!showLineups)}>{!showLineups ? 'Show Lineups' : 'Hide Lineups'}</Button>

            {showLineups &&
                lines.map(line => (
                    <div key={'lineid' + line} >
                        <div style={{ marginTop: 20 }}>
                            {line}.
                        </div>
                        <ShowLine
                            line={line}
                            pos='VL'
                        />
                        <ShowLine
                            line={line}
                            pos='KH'
                        />
                        <ShowLine
                            line={line}
                            pos='OL'
                        />
                        <ShowLine
                            line={line}
                            pos='VP'
                        />
                        <ShowLine
                            line={line}
                            pos='OP'
                        />

                    </div>
                ))}
            {showLineups &&
                <div style={{ marginBottom: 20 }}>
                    <div style={{ marginTop: 20 }}>
                        Goalkeepers
                    </div>
                    <ShowLine
                        line={1}
                        pos='MV'
                    />
                    <ShowLine
                        line={2}
                        pos='MV'
                    />
                </div>
            }
        </div>
    );

};

export default FloorballLineups;