import React, { useRef, useEffect } from 'react';
import { useLocation, Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutProfile from './layouts/LayoutProfile';
import LayoutPrivacy from './layouts/LayoutPrivacy';


// Views 
import Home from './views/Home';
import Profile from './views/Profile';
import ResetPassword from './views/ResetPassword';
import Floorball from './views/Floorball';
import PrivacyPolicy from './views/PrivacyPolicy';


// Initialize Google Analytics
//ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize('G-REJGRVY0NZ');

const trackPage = page => {
  ReactGA.set({ page });
  //ReactGA.pageview(page);
  ReactGA.send({ hitType: "pageview", page: page, title: page });
  console.log("trackpage: ", page)
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Routes>
          <Route path="floorball" element={<AppRoute component={Floorball} layout={LayoutProfile} />} />
          <Route path="reset-password" element={<AppRoute component={ResetPassword} layout={LayoutProfile} />} />
          <Route path="profile/:name" element={<AppRoute component={Profile} layout={LayoutProfile} />} />
          <Route path="privacypolicy" element={<AppRoute component={PrivacyPolicy} layout={LayoutPrivacy} />} />
          <Route path="*" element={<AppRoute component={Home} layout={LayoutDefault} />} />

          {/*
          <AppRoute path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/profile/:name" component={Profile} layout={LayoutProfile} />
          <AppRoute exact path="/reset-password" component={ResetPassword} layout={LayoutProfile} />
          <AppRoute exact path="/floorball" component={Floorball} layout={LayoutProfile} />
          
      */}
        </Routes>
      )} />
  );
}

export default App;