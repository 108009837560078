import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../elements/Image';


const Row = ({ children }) => (

    <div style={{
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    }}>
        {children}
    </div>
);

export default Row;