import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}




const FaqSection = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {


    const [match, setMatch] = useState(null);



    const outerClasses = classNames(
        'testimonial section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );
    const sectionHeader = {
        title: 'FAQ',
        paragraph: 'Frequently asked questions'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm" style={{ marginTop: 40 }}>

                <SectionHeader data={sectionHeader} className="left-content" />
                <div className="hero-inner ">
                    <div className="hero-content reveal-from-right" data-reveal-delay="200">
                        <Accordion className="tiles-item-inner" >
                            <AccordionSummary
                                style={{ color: '#ECEDED' }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How to delete account?</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ color: '#9CA9B3', textAlign: 'left' }}
                            >
                                <Typography>
                                    You can remove account and related data with two ways: <br /><br />
                                    1. Open PonyShare application, go to Profile view and you can find delete account button from the bottom of the screen.
                                    <br /><br />
                                    2. Send email to info.ponyshare@ponystories.com from your account email and request account removal.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="tiles-item-inner" >
                            <AccordionSummary
                                style={{ color: '#ECEDED' }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>What is deleted with account?</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ color: '#9CA9B3', textAlign: 'left' }}
                            >
                                <Typography>
                                    All the personal data is removed or anonymized.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>

        </section >
    );
}

FaqSection.propTypes = propTypes;
FaqSection.defaultProps = defaultProps;

export default FaqSection;