import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image';
import Row from '../Row';
import FloorballEvents from './FloorballEvents';
import FlooerballHeader from './FlooerballHeader';
import FloorballLineups from './FloorballLineups';

const FloorballMatches = ({ games, mobile, showMatch, getMatch }) => {


    return (

        <div className="container-xs space-between" style={{ maxWidth: '500' }}>
            {games &&
                games.map(match => (
                    <div key={match.match_id}>
                        <div style={{ margin: 50 }} onClick={() => { getMatch(match.match_id) }}>
                            <FlooerballHeader match={match} />
                            <Row>
                                <div>
                                    <Image
                                        src={match.club_A_crest}
                                        width={mobile ? 50 : 100} />
                                    {match.team_A_abbrevation}
                                </div>

                                {match.status != 'Fixture' &&
                                    <h2>
                                        {match.fs_A}  - {match.fs_B}
                                    </h2>
                                }
                                <div>
                                    <Image
                                        src={match.club_B_crest}
                                        width={mobile ? 50 : 100} />
                                    {match.team_B_abbrevation}
                                </div>
                            </Row>

                        </div>
                        {(showMatch && showMatch.match_id === match.match_id) &&
                            <>
                                <FloorballLineups
                                    match={showMatch}
                                />
                                <FloorballEvents
                                    match={showMatch}
                                />
                            </>
                        }
                    </div>
                ))
            }
        </div>
    );

};

export default FloorballMatches;