import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
//import Switch from '../elements/Switch';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';
import { useParams, useLocation } from "react-router-dom";
import { Datepicker } from "../DatepickerJs";
import '../../App.css';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { enUS } from "date-fns/locale";
import { format } from "date-fns";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Sync } from '@mui/icons-material';

import CheckMobileScreen from '../../utils/CheckMobileScreen';
import FloorballMatches from '../elements/floorball/FloorballMatches';
import FloorballMatchesSimple from '../elements/floorball/FloorballMatchesSimple';
//import profiles from '../../assets/profiles.json'
import AdsComponent from '../elements/AdsComponent'
import ReactGA from "react-ga4";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}




const FloorballSection = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const [date, setDate] = useState({
        startValue: null,
        endValue: null,
        rangeDates: [],
    });

    const [simplified, setSimplified] = React.useState(false);
    const [games, setGames] = useState(null);
    const [match, setMatch] = useState(null);

    const api = axios.create({
        baseURL: 'https://salibandy.api.torneopal.com/taso/rest/',
        headers: {
            Accept: 'json/cxxn98veus9y4qrfzawx'
        }
    })

    useEffect(() => {

        if (date.startValue) {
            const newDate = format(date.startValue, "yyyy-MM-dd")
            console.log("date.startValue ", newDate)
            getGames(newDate);
        }

    }, [date.startValue])

    useEffect(() => {
        setDate((prev) => ({ ...prev, startValue: new Date() }));
    }, [])



    const getGames = async (date) => {
        const response = await api.get('getMatches?date=' + date + '&competition_id=sb2023&category_id=384,402,401,444,952&tpid=-805451447');
        console.log("response: ", response)

        if (response.status === 200) {
            setGames(response.data.matches);
            console.log("response.data: ", response.data)
        }
        ReactGA.event({
            category: "update",
            action: "update_matches"
        });
    }

    const getMatch = async (id) => {
        if (id === match?.match_id) {
            setMatch(null);
            return;
        }
        const response = await api.get('getMatch?match_id=' + id + '&tpid=-805451447');
        console.log("response: ", response)

        if (response.status === 200) {
            setMatch(response.data.match);
            console.log("response.data: ", response.data)
        }
    }

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );
    const buttonPrev = { background: 'transparent' };
    const datePickerClass = classNames(
        'prevIcon',
    );
    const handleChange = (d) => {
        const [startValue, endValue, rangeDates] = d;
        setDate((prev) => ({ ...prev, endValue, startValue, rangeDates }));
        console.log("startValue ", format(startValue, "yyyy-MM-dd"))
    };
    const mobile = CheckMobileScreen()

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className="hero-inner ">
                    <div className="hero-content">
                        <Image
                            style={{ marginBottom: 20 }}
                            className="has-shadow"
                            src={require('./../../assets/images/logo_transparent_background.png')}
                            alt="Hero"
                            width={896}
                            height={504} />
                        <div style={{ margin: 20 }}>
                            <Datepicker
                                onChange={handleChange}
                                classNames={datePickerClass}
                                //locale={enUS}
                                startValue={date.startValue}
                                endValue={date.startValue}
                            />
                        </div>
                        <FormControlLabel control={
                            <Switch
                                checked={simplified}
                                onChange={() => setSimplified(!simplified)}
                            />} label="Simplified view"
                        />
                        {simplified ?
                            <FloorballMatchesSimple
                                games={games}
                                mobile={mobile}
                            />
                            :
                            <FloorballMatches
                                games={games}
                                mobile={mobile}
                                showMatch={match}
                                getMatch={(id) => getMatch(id)}
                            />
                        }
                        {games?.length === 0 &&
                            "No games at selected date"}
                        {
                            //<AdsComponent dataAdSlot='2139350315' />
                        }
                        {isMobile &&
                            <Fab
                                color="primary"
                                aria-label="add"
                                style={{ position: 'fixed', bottom: 20, right: 20 }}
                                onClick={() => { getGames(format(date.startValue, "yyyy-MM-dd")); setMatch(null) }}
                            >
                                <Sync />
                            </Fab>
                        }
                    </div>
                </div>
            </div>

        </section >
    );
}

FloorballSection.propTypes = propTypes;
FloorballSection.defaultProps = defaultProps;

export default FloorballSection;